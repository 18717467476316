import { defineComponent } from 'vue';
export default defineComponent({
    name: 'SwitchGroup',
    props: {
        hrefOne: String,
        hrefTwo: String,
    },
    mounted() {
        document
            .querySelector('#switch_toggle')
            ?.addEventListener('click', function () {
            const toggleClass = (el, className) => el.classList.toggle(className);
            toggleClass(document.querySelector('.switch-group__content-two'), 'hide');
            toggleClass(document.querySelector('.switch-group__content-one'), 'hide');
            toggleClass(document.querySelector('.switch-group__spans.first'), 'selected');
            toggleClass(document.querySelector('.switch-group__spans.second'), 'selected');
        });
    },
});
